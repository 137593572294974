export const formatGatsbyDate = (dateString) => {
	const [dateOnly] = dateString.split('T');
	const [year, month, day] = dateOnly.split('-').map((chunk) => parseInt(chunk));

	return new Date(year, month - 1, day).toLocaleString('default', {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	});
};
