import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { safePrefix } from '../utils';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>
						{_.get(this.props, 'pageContext.frontmatter.title') &&
							_.get(this.props, 'pageContext.frontmatter.title') + ' - '}
						{_.get(this.props, 'pageContext.site.siteMetadata.title')}
					</title>
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
					<link rel="stylesheet" href={safePrefix('assets/css/main.css')} />
					<link rel="stylesheet" href={safePrefix('assets/css/markdown-images.css')} />

					{/* Favicon */}
					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
					<link rel="manifest" href="/site.webmanifest" />
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#262E44" />
					<meta name="msapplication-TileColor" content="#262E44" />
					<meta name="theme-color" content="#262E44" />
				</Helmet>
				{_.get(this.props, 'pageContext.frontmatter.template') === 'home' ? (
					<Sidebar {...this.props} />
				) : (
					<Header {...this.props} />
				)}
				{this.props.children}
				<Footer {...this.props} />
			</React.Fragment>
		);
	}
}
