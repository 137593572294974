import Contact from './Contact';
import Features from './Features';
import Footer from './Footer';
import Header from './Header';
import Intro from './Intro';
import Sidebar from './Sidebar';
import Spotlights from './Spotlights';
import Layout from './Layout';

export { Contact, Features, Footer, Header, Intro, Sidebar, Spotlights, Layout };

export default {
	Contact,
	Features,
	Footer,
	Header,
	Intro,
	Sidebar,
	Spotlights,
	Layout,
};
