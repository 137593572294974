import React from 'react';
import _ from 'lodash';

import { useStaticQuery, graphql } from 'gatsby';
import { Link, safePrefix, markdownify, formatGatsbyDate } from '../utils';

export default (props) => {
	const { allSitePage: data } = useStaticQuery(graphql`
		query RecentEpisodePages {
			allSitePage(
				limit: 3
				sort: { fields: context___frontmatter___date, order: DESC }
				filter: { context: { frontmatter: { type: { eq: "episode" } } } }
			) {
				nodes {
					context {
						url
						frontmatter {
							date
							excerpt
							thumbnail
							thumbnail_alt
							thumbnail_position
							title
							number
						}
					}
				}
			}
		}
	`);

	return (
		<section
			id={_.get(props, 'section.section_id')}
			className={'wrapper ' + _.get(props, 'section.background_style') + ' spotlights'}
		>
			{data.nodes.map(({ context }, index) => (
				<section key={index}>
					{context.frontmatter.thumbnail && (
						<Link to={safePrefix(context.url)} className="image">
							<img
								src={safePrefix(context.frontmatter.thumbnail)}
								alt={context.frontmatter.thumbnail_alt}
								data-position={context.frontmatter.thumbnail_position}
							/>
						</Link>
					)}
					<div className="content">
						<div className="inner">
							<h2>
								#{context.frontmatter.number} - {context.frontmatter.title}
							</h2>
							{context.frontmatter.date && (
								<span>{formatGatsbyDate(context.frontmatter.date)}</span>
							)}
							{markdownify(context.frontmatter.excerpt)}
							<ul className="actions">
								<li>
									<Link to={safePrefix(context.url)} className="button">
										Listen now
									</Link>
								</li>
							</ul>
						</div>

						{index === data.nodes.length - 1 && (
							<Link to="/episodes" className="button primary">
								All Episodes &gt;
							</Link>
						)}
					</div>
				</section>
			))}
		</section>
	);
};
