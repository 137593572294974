import React from 'react';
import _ from 'lodash';

import { Link, safePrefix, classNames } from '../utils';

export default class Header extends React.Component {
	render() {
		return (
			<header id="header">
				<Link to={safePrefix('/')} className="title">
					{_.get(this.props, 'pageContext.site.siteMetadata.logo') ? (
						// If there's a logo, show it
						<img
							src={_.get(this.props, 'pageContext.site.siteMetadata.logo')}
							alt={_.get(this.props, 'pageContext.site.siteMetadata.title')}
							style={{ maxHeight: 70, marginTop: 20 }}
						/>
					) : (
						// Otherwise just the text
						_.get(this.props, 'pageContext.site.siteMetadata.title')
					)}
				</Link>
				<nav>
					<ul>
						{_.map(_.get(this.props, 'pageContext.menus.main'), (item, item_idx) => (
							<li key={item_idx}>
								<Link
									to={safePrefix(_.get(item, 'url'))}
									className={classNames({
										active: _.get(item, 'url') === _.get(this.props, 'pageContext.url'),
									})}
								>
									{_.get(item, 'title')}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</header>
		);
	}
}
